import { FC } from 'react';
import IconProps from './IconProps';

const AddUserIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2301 4.39575C10.2301 3.99304 9.90361 3.66659 9.50091 3.66659C9.0982 3.66659 8.77174 3.99304 8.77174 4.39575H10.2301ZM8.77174 9.4999C8.77174 9.90261 9.0982 10.2291 9.50091 10.2291C9.90361 10.2291 10.2301 9.90261 10.2301 9.4999H8.77174ZM10.2301 9.49997C10.2301 9.09726 9.90361 8.7708 9.50091 8.7708C9.0982 8.7708 8.77174 9.09726 8.77174 9.49997H10.2301ZM8.77174 14.6041C8.77174 15.0068 9.0982 15.3333 9.50091 15.3333C9.90361 15.3333 10.2301 15.0068 10.2301 14.6041H8.77174ZM9.50006 8.77073C9.09735 8.77073 8.77089 9.09719 8.77089 9.4999C8.77089 9.90261 9.09735 10.2291 9.50006 10.2291V8.77073ZM14.6042 10.2291C15.0069 10.2291 15.3334 9.90261 15.3334 9.4999C15.3334 9.09719 15.0069 8.77073 14.6042 8.77073V10.2291ZM9.50015 10.2291C9.90285 10.2291 10.2293 9.90261 10.2293 9.4999C10.2293 9.09719 9.90285 8.77073 9.50015 8.77073V10.2291ZM4.396 8.77073C3.99329 8.77073 3.66683 9.09719 3.66683 9.4999C3.66683 9.90261 3.99329 10.2291 4.396 10.2291V8.77073ZM8.77174 4.39575V9.4999H10.2301V4.39575H8.77174ZM8.77174 9.49997V14.6041H10.2301V9.49997H8.77174ZM9.50006 10.2291H14.6042V8.77073H9.50006V10.2291ZM9.50015 8.77073H4.396V10.2291H9.50015V8.77073Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AddUserIcon;
